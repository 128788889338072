import moment from 'moment';
import UAParser from "ua-parser-js";
import { useAppStateContext } from '../../AppStateProvider/AppStateProvider';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ErrorIcon } from '../../../icons/ErrorIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      justifyContent: 'space-between',
    },
    /* 
    The size of the image is explicitly stated here so that this content can properly be centered vertically
    before the image is loaded.
    */
    illustration: {
      width: '284px',
      height: '284px',
      [theme.breakpoints.down('md')]: {
        width: '200px',
        height: '200px',
      },
    },
    downloadReportBtnRow: {
      padding: "6px 0 0 0"
    },
    downloadReportButton : {
      padding: "6px 0px",
      textDecoration : 'underline',
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
  })
);

export function GetStarted() {
  const { nextPane, state } = useAppStateContext();
  const classes = useStyles();

  const userAgentParser = new UAParser();
  const userAgentInfo = userAgentParser.getResult();

  const handleDownloadReport = (
    event: React.FormEvent<EventTarget | HTMLFormElement>,
  ) => {
    event.preventDefault();
    const formattedDate = moment().utc().format('MMMM Do YYYY hh mm a').replaceAll(' ', '_');
    const testResult = "Connection Error";
    const blitzzServerAPIStatus = state.appIsExpired === true ? "Failed" :  "Pass";
    
    const blitzzStorageAPIStatus = state.storageAPIStatus === false ? "Failed" :  "Pass";
    const blitzzMediaAPIStatus = state.mediaConnectionStatus === false ? "Failed" :  "Pass";

    const signalingGateway = state.preflightTest.signalingGatewayReachable
      ? "Reachable"
      : "Unreachable";
    const turnServers = state.preflightTest.turnServersReachable
      ? "Reachable"
      : "Unreachable";
    const maxBitrate = state.bitrateTest.report?.values
      ? Math.max(...state.bitrateTest.report.values)
      : 0;

    const finalTestResults = {
        testReport: {
        summary: testResult,
        Description: "Please try again after sometime.",
        Date: formattedDate,
        'Server API Status': blitzzServerAPIStatus,
        'Media API Status': blitzzMediaAPIStatus,
        'Storage API Status': blitzzStorageAPIStatus,
      },
      audioTestResults: {
        inputTest: state.audioInputTestReport,
        outputTest: state.audioOutputTestReport,
      },
      bitrateTestResults: { maxBitrate, ...state.bitrateTest.report },
      browserInformation: userAgentInfo,
      connectivityResults: {
        twilioServices: { ...state.twilioStatus },
        signalingRegion: signalingGateway,
        TURN: turnServers,
      },
      preflightTestReport: {
        report: state.preflightTest.report,
        error: state.preflightTest.error?.message || null,
      },
      videoTestResults: state.videoInputTestReport,
    };

    //format data
    const formattedData = JSON.stringify(finalTestResults, null, 2)

    //download file
    const fileName = `Network_Test_${testResult}_${formattedDate}(UTC).txt`;
    const data = new Blob([formattedData], { type: "text/plain" });

    const jsonURL = window.URL.createObjectURL(data);
    const link = document.createElement("a");

    document.body.appendChild(link);
    link.href = jsonURL;
    link.setAttribute("download", fileName);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container>
      <Grid container alignItems="center" className={classes.gridContainer}>
        <Grid item lg={5}>
          {state.appIsExpired ? (
            <>
              <Typography variant="h1" gutterBottom>
                <ErrorIcon />
                Connection Error
              </Typography>

              <Typography variant="body1">
                Please try again after sometime.
              </Typography>

              <div className={classes.downloadReportBtnRow}>
                <Button
                    variant="text"
                    onClick={(e)=> handleDownloadReport(e)}
                    className={classes.downloadReportButton}
                  >
                    Download report
                  </Button>
              </div>
            </>
          ) : (
            <>
              {!state.blitzzTestInProgress ? (<>
              <Typography variant="h1" gutterBottom>
                Let's get started!
              </Typography>

              <Typography variant="body1" gutterBottom>
                Let's check your network, audio, and video to make sure you are ready for the call. Shall we get started?
              </Typography></>) :
              (
              <>
              <Typography variant="h1" gutterBottom>
                  Loading...
                </Typography>
                <Typography variant="body1" gutterBottom>
                Checking your system settings
              </Typography>
              </>)}
            </>
          )}
          {!state.blitzzTestInProgress && <Button
            variant="contained"
            color="primary"
            onClick={nextPane}
            style={{ marginBottom: '1em' }}
            disabled={state.appIsExpired}
          >
            Get started
          </Button>}
        </Grid>
      </Grid>
    </Container>
  );
}