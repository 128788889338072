import {
  createStyles,
  makeStyles,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Box,
  Button,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import { useState, FormEvent, useRef } from "react";
const NAME_MAX_LENGTH = 100;
const REASON_MAX_LENGTH = 1000;
const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      width: "100%",
      maxWidth: "600px",
    },
    content: {
      padding: "2em",
      [theme.breakpoints.down("sm")]: {
        padding: "1em",
      },
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    iconContainer: {
      display: "flex",
      alignItems: "center",
      "& div": {
        display: "flex",
        alignItems: "center",
      },
      "& svg": {
        marginRight: "0.3em",
      },
    },
    inputcharCount: {
      float: 'right',
      fontSize: '13px',
      opacity: '0.7',
      padding: '2px 0 0 0',
    },
    textFieldPlaceholderStyle: {
      "& span":{
        paddingRight: '15px'
      }
    }
  })
);
interface FormElements extends HTMLFormControlsCollection {
  enterNameID: HTMLInputElement
}

interface EnterNameFormElement extends HTMLFormElement {
 readonly elements: FormElements
}

// interface FormElements extends HTMLFormControlsCollection {
//   yourInputName: HTMLInputElement
// }

// interface YourFormElement extends HTMLFormElement {
//  readonly elements: FormElements
// }
type SubmitEvent = FormEvent<HTMLFormElement>;

interface ReportModalProps {
  isModalOpen: boolean;
  isReportButtonDisable: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  setIsReportButtonDisable: (isReportButtonDisable: boolean) => void;
  sendTestResults: (e: SubmitEvent) => void;
  enterName: string ;
  setEnterName: (enterName: string ) => void;
  reason: string ;
  setReason: (reason: string ) => void;
  isLoading: boolean ;
  setIsLoading: (isLoading: boolean ) => void;

}

  export const ReportModal = ({isModalOpen, isReportButtonDisable, setIsModalOpen, setIsReportButtonDisable, sendTestResults, enterName, setEnterName, reason, setReason, isLoading, setIsLoading}: ReportModalProps) => {
  const classes = useStyles();
  const formRef = useRef(null);
  const handleEnterName = (eventVal: any) => {
    const nameVal = eventVal.target.value.substring(0, NAME_MAX_LENGTH);
    setEnterName(nameVal);
  };

  const handleReason = (eventVal: any) => {
    const reasonVal = eventVal.target.value.substring(0, REASON_MAX_LENGTH);
    setReason(reasonVal);
  };

  const connectButton = () => {
    // formRef.current.submit();
    console.log('11')
  }
  const closePopupModal = () => {
    setEnterName('')
    setReason('')
    setIsModalOpen(false)
  }
  return (
    <>
      <Dialog
        open={isModalOpen}
        onClose={() => closePopupModal()}
        classes={{ paper: classes.modal }}
      >
        <DialogTitle disableTypography>
          <Typography variant="h3">Share Test Report</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => closePopupModal()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
        <Typography variant="body1">
                Add more details before sharing. This will help us troubleshoot 
                your issues when you contact our tech support team.
              </Typography>
          <form
            ref={formRef}
            id="login"
            onSubmit={(e) => sendTestResults(e)}
            noValidate
          >
            <Box
              sx={{
                py: 1,
                display: "grid",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div className="form-group" style={{ paddingBottom: "20px" }}>
                <div className={classes.inputcharCount}>
                  {NAME_MAX_LENGTH - enterName.length}
                </div>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  id="enterNameID"
                  label="Full name"
                  onChange={(e) => handleEnterName(e)}
                  value={enterName}
                  inputProps={{ maxLength: NAME_MAX_LENGTH }}
                  className={classes.textFieldPlaceholderStyle}
                />
              </div>
              <div className="form-group">
              <div className={classes.inputcharCount}>
                  {REASON_MAX_LENGTH - reason.length}
                </div>
                <TextField
                  fullWidth
                  required
                  multiline
                  minRows={4}
                  variant="outlined"
                  id="Reason"
                  label="Message"
                  onChange={(e) => handleReason(e)}
                  value={reason}
                  inputProps={{ maxLength: REASON_MAX_LENGTH }}
                  className={classes.textFieldPlaceholderStyle}
                />
              </div>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={
              (enterName.trim().length < 1 || reason.trim().length < 1) || isReportButtonDisable
            }
            onClick={(e) => {} }
            form='login'
          >
            {isLoading && <CircularProgress size={16}/>}
            Send Report
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
