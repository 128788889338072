import { default as appInfo } from '../package.json';

export const APP_NAME = appInfo.name;

export const AUDIO_LEVEL_THRESHOLD = 200;

export const AUDIO_LEVEL_STANDARD_DEVIATION_THRESHOLD = AUDIO_LEVEL_THRESHOLD * 0.05; // 5% threshold

export const INPUT_TEST_DURATION = 20000;

export const RECORD_DURATION = 4000;

export const LOCAL_STORAGE_PREFIX = "Blitzz";
export const LOCAL_STORAGE_KEY=process.env.REACT_APP_LOCAL_STORAGE;
export const VERSION_KEY = 'version';
export const ACTUAL_MEETINGID = '204409442';
export const UPLOADFILE = 'v1/Session/UploadFile';
export const UPLOAD_TEMP_FILE = 'v1/Misc/UploadSessionFile';
export const STORAGE_API = 'https://storageapi.blitzz.co/api/';
export const NETWORK_TEST_API = 'api/Twilio/NetworkTest';
export const BLITZZ_MEDIA_IMAGE = 'https://blitzzmedia.blob.core.windows.net/misc/Blitzz-Logo-right_800x330.png';
export const NETWORK_TEST_EMAIL = 'api/Mail/Send';

