import { useState } from 'react';
import moment from "moment";
import { useAppStateContext } from '../../../AppStateProvider/AppStateProvider';
import { createStyles, makeStyles, Button, Container, Grid, Typography, Paper } from '@material-ui/core';
import { ErrorIcon } from '../../../../icons/ErrorIcon';
import { ViewIcon } from '../../../../icons/ViewIcon';
import { SendIcon } from '../../../../icons/SendIcon';
import { ReportModal } from '../../Results/ReportModal/ReportModal';
import UAParser from "ua-parser-js";
import axios, { AxiosError } from 'axios';
import { NETWORK_TEST_EMAIL } from '../../../../constants';
import { CustomToast } from '../../../../utils/customToast';
const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      float: 'left',
      [theme.breakpoints.down('md')]: {
        float: 'initial',
      },
    },
    heading: {
      position: 'relative',
      marginTop: '20px',
    },
    paperContainer: {
      float: 'right',
      marginRight: '1em',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        float: 'initial',
        justifyContent: 'center',
        margin: '0 0 2.5em 0',
      },
    },
    paper: {
      padding: '1.5em',
      borderRadius: '8px',
      maxWidth: '400px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    buttonContainer: {
      display: "inline-flex",
      flexWrap: "wrap",
      gap: "1em",
      width: "100%",
    },
    downloadButton: {
      "& svg": {
        position: "relative",
        left: "-5px",
      },
    },
    restartButton: {
      backgroundColor: "#FFFFFF",
      borderColor: "#8891AA",
    },
    downloadReportBtnRow: {
      padding: "6px 0 0 0"
    },
    downloadReportButton : {
      padding: "6px 0px",
      textDecoration : 'underline',
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    viewButton: {
      marginTop: '2em',
      '& svg': {
        position: 'relative',
        left: '-5px',
      },
    },
  })
);

interface ConnectionFailedProps {
  openModal: () => void;
}

export function ConnectionFailed({ openModal }: ConnectionFailedProps) {
  const classes = useStyles();
  const { state, downloadFinalTestResults } = useAppStateContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReportButtonDisable, setIsReportButtonDisable] = useState(false);
  const [enterName, setEnterName] = useState("");
  const [reason, setReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userAgentParser = new UAParser();
  const userAgentInfo = userAgentParser.getResult();

  const handleDownloadReport = (reportData : string, date : string) => {

    const fileName = `Network_Test_Connection_Failed_${date}(UTC).txt`;
    const data = new Blob([reportData], { type: "text/plain" });

    const jsonURL = window.URL.createObjectURL(data);
    const link = document.createElement("a");

    document.body.appendChild(link);
    link.href = jsonURL;
    link.setAttribute("download", fileName);
    link.click();
    document.body.removeChild(link);
  }

  const sendTestResults = (
    event: React.FormEvent<EventTarget | HTMLFormElement>,
    isDownloadReport?:boolean 
  ) => {
    event.preventDefault();
    setIsLoading(true);
    console.log(event.currentTarget);
    // const enterNameValue = document.getElementById(
    //   "enterNameID"
    // ) as HTMLInputElement | null;
    // const ReasonValue = document.getElementById(
    //   "Reason"
    // ) as HTMLInputElement | null;
    // let enterName = null;
    // let reason = null;
    // if (enterNameValue != null) {
    //   enterName = enterNameValue.value;
    // }
    // if (ReasonValue != null) {
    //   reason = ReasonValue.value;
    // }

    const signalingGateway = state.preflightTest.signalingGatewayReachable
      ? "Reachable"
      : "Unreachable";
    const turnServers = state.preflightTest.turnServersReachable
      ? "Reachable"
      : "Unreachable";
    const maxBitrate = state.bitrateTest.report?.values
      ? Math.max(...state.bitrateTest.report.values)
      : 0;

    const finalTestResults = {
      audioTestResults: {
        inputTest: state.audioInputTestReport,
        outputTest: state.audioOutputTestReport,
      },
      bitrateTestResults: { maxBitrate, ...state.bitrateTest.report },
      browserInformation: userAgentInfo,
      connectivityResults: {
        twilioServices: { ...state.twilioStatus },
        signalingRegion: signalingGateway,
        TURN: turnServers,
      },
      preflightTestReport: {
        report: state.preflightTest.report,
        error: state.preflightTest.error?.message || null,
      },
      videoTestResults: state.videoInputTestReport,
    };
    const blitzzServerAPIStatus = state.appIsExpired === true ? "Failed" :  "Pass";
    const blitzzStorageAPIStatus = state.storageAPIStatus === false ? "Failed" :  "Pass";
    const blitzzMediaAPIStatus = state.mediaConnectionStatus === false ? "Failed" :  "Pass";
    const formattedDate = moment().utc().format('MMMM Do YYYY hh mm a').replaceAll(' ', '_')
    const testReport = {
      summary  : "Connection_Failed",
      Description : "It's possible that you're behind a firewall that will need to be updated by your network administrator",
      Date : formattedDate,
      'Server API Status' : blitzzServerAPIStatus,
      'Media API Status': blitzzMediaAPIStatus,
      'Storage API Status': blitzzStorageAPIStatus,
    }

    const reportData = JSON.stringify(finalTestResults, null, 2);

    const updatedData = JSON.parse(reportData)
    const updatedReportData = {testReport, ...updatedData};
    const formattedData = JSON.stringify(updatedReportData, null, 2)

    if(isDownloadReport){
      handleDownloadReport(formattedData, formattedDate)
    }
    else{

    let url = process.env.REACT_APP_API_END_POINT + NETWORK_TEST_EMAIL;

    const headers = {
      "Content-Type": "application/json",
      CLIENTAPPID: process.env.REACT_APP_CLIENTAPPID,
    };
    const requestData = {
      MAILTYPE: "NETWORKTEST",
      NETWORKTESTRESULT: formattedData,
      NAME: enterName,
      REASON: reason,
      RESULT: 'Connection_Failed'
    };
    setIsReportButtonDisable(true);
    axios
      .post(url, requestData, { headers: headers })
      .then((response: any) => {
        // CustomToast.success("Email send successful");
        if (response.data.success > 0) {
          console.log("response", response);
          setEnterName('')
          setReason('')
          setIsLoading(false);
          CustomToast.success("Your report has been sent successfully");
          setIsReportButtonDisable(false);
          setIsModalOpen(false)
        } else {
          setIsLoading(false);
          setIsReportButtonDisable(false);
          CustomToast.error('Unable to send Report results. Please try again.');
        }
      })
      .catch((error: AxiosError) => {
        setIsLoading(false);
        setIsReportButtonDisable(false);
        CustomToast.error('Unable to send Report results. Please try again.');
      });
    }
  };

  return (
    <>
      <Container>
        <div>
          <Grid item lg={5} className={classes.header}>
            <Typography variant="h1" gutterBottom className={classes.heading}>
              <ErrorIcon />
              Connection failed
            </Typography>

            <Typography variant="body1" gutterBottom>
              It's possible that you're behind a firewall that will need to be
              updated by your network administrator.
            </Typography>
          </Grid>

          <Grid item lg={5} className={classes.paperContainer}>
            <Paper className={classes.paper}>
              <Typography variant="body1" gutterBottom>
                To conduct a video call, your internet needs to be able to
                communicate with cloud.
              </Typography>

            </Paper>
            <Button
              variant="outlined"
              onClick={openModal}
              className={classes.viewButton}
            >
              <ViewIcon />
              View detailed connection information
            </Button>
          </Grid>

          <Grid item lg={5}>
            <Typography variant="body1" gutterBottom>
            Share the <a href='https://go.blitzz.co/firewall' target='_blank'>Network Requirements</a> link with your network admin to update your firewall settings and re-run this test.
            </Typography>

            <div>
            <div className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.downloadButton}
                    onClick={() => setIsModalOpen(true)}
                  >
                    <SendIcon />
                    Send Report
                  </Button>
                  <Button
                    variant="outlined"
                    className={classes.restartButton}
                    href='https://go.blitzz.co/firewall'
                    target='_blank'
                  >
                    Network Requirements
                  </Button>
            </div>
              <div className={classes.downloadReportBtnRow}>
                <Button
                    variant="text"
                    onClick={(e)=> {
                      const downloadReport = true;
                      sendTestResults(e, downloadReport)
                    }}
                    className={classes.downloadReportButton}
                  >
                    Download report
                  </Button>
                  </div>
            </div>
          </Grid>
        </div>
      </Container>
      <ReportModal
        isModalOpen={isModalOpen}
        isReportButtonDisable={isReportButtonDisable}
        setIsReportButtonDisable={setIsReportButtonDisable}
        setIsModalOpen={setIsModalOpen}
        sendTestResults={(e) => sendTestResults(e)}
        enterName={enterName}
        setEnterName={setEnterName}
        reason={reason}
        setReason={setReason}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </>
  );
}
