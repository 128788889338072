export function SendIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4457 4.55429L6.16566 8.22019L0.642996 6.37906C0.257505 6.2503 -0.00220389 5.88862 1.40967e-05 5.48229C0.00226127 5.07596 0.264947 4.7165 0.651927 4.59223L14.7715 0.0452131C15.1071 -0.0626804 15.4755 0.025864 15.7248 0.275183C15.9741 0.524503 16.0627 0.892864 15.9548 1.22851L11.4078 15.3481C11.2835 15.7351 10.924 15.9977 10.5177 16C10.1114 16.0022 9.7497 15.7425 9.62094 15.357L7.77087 9.80757L11.4457 4.55429Z"
        fill="white"
      />
    </svg>
  );
}
