import {
  makeStyles,
  Button,
  Container,
  Grid,
  Typography,
  Theme,
  createStyles,
  Hidden,
} from "@material-ui/core";
import moment from "moment";
import {
  ActivePane,
  useAppStateContext,
} from "../../AppStateProvider/AppStateProvider";
import { CheckMark } from "../../../icons/CheckMark";
import { DownloadIcon } from "../../../icons/DownloadIcon";
import { getQualityScore } from "../Quality/getQualityScore/getQualityScore";
import { QualityScore } from "../Quality/Quality";
import { SmallError } from "../../../icons/SmallError";
import SomeFailed from "./SomeFailed.png";
import TestsPassed from "./TestsPassed.png";
import { QualityModal } from "../Quality/QualityModal/QualityModal";
import { FormEvent, useState, FC } from "react";
import { ReportModal } from "./ReportModal/ReportModal";
import UAParser from "ua-parser-js";
import axios, { AxiosError } from "axios";
import { NETWORK_TEST_EMAIL } from "../../../constants";
import {CustomToast} from '../../../utils/customToast'
import { SendIcon } from "../../../icons/SendIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      float: "left",
      [theme.breakpoints.down("md")]: {
        float: "initial",
        paddingBottom: "1em",
      },
    },
    buttonContainer: {
      display: "inline-flex",
      flexWrap: "wrap",
      gap: "1em",
      width: "100%",
    },
    resultsList: {
      float: "right",
      [theme.breakpoints.down("md")]: {
        float: "initial",
      },
    },
    resultContainer: {
      marginTop: "1.5em",
      "&:not(:last-child)": {
        paddingBottom: "1.5em",
        borderBottom: "0.1em solid #8891AA",
      },
      [theme.breakpoints.down("md")]: {
        "&:last-child": {
          paddingBottom: "1.5em",
        },
      },
    },
    iconContainer: {
      display: "flex",
      "& svg": {
        margin: "0.2em 0.8em 0 0",
      },
    },
    downloadButton: {
      "& svg": {
        position: "relative",
        left: "-5px",
      },
    },
    restartButton: {
      backgroundColor: "#FFFFFF",
      borderColor: "#8891AA",
    },
    downloadReportBtnRow: {
      padding: "6px 0 0 0"
    },
    downloadReportButton : {
      padding: "6px 0px",
      textDecoration : 'underline',
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    illustration: {
      marginTop: "7em",
    },
    hardwareButton: {
      marginRight: "1.5em",
    },
    gutterBottom: {
      marginBottom: "1em",
      [theme.breakpoints.down("md")]: {
        marginBottom: "1.5em",
      },
    },
  })
);

export const Results = () => {
  const { state, downloadFinalTestResults, dispatch } = useAppStateContext();
  const { totalQualityScore } = getQualityScore(
    state.preflightTest.report,
    state.bitrateTest.report
  );
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReportButtonDisable, setIsReportButtonDisable] = useState(false);
  const [enterName, setEnterName] = useState("");
  const [reason, setReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const testsPassed =
    totalQualityScore === QualityScore.Excellent ||
    totalQualityScore === QualityScore.Good;
  const qualityScore = QualityScore[totalQualityScore].toLowerCase();
  const userAgentParser = new UAParser();
  const userAgentInfo = userAgentParser.getResult();

  const handleDownloadReport = (reportData : string, result : string, date : string) => {

    const fileName = `Network_Test_${result}_${date}(UTC).txt`;
    const data = new Blob([reportData], { type: "text/plain" });

    const jsonURL = window.URL.createObjectURL(data);
    const link = document.createElement("a");

    document.body.appendChild(link);
    link.href = jsonURL;
    link.setAttribute("download", fileName);
    link.click();
    document.body.removeChild(link);
  }

  const sendTestResults = (
    event: React.FormEvent<EventTarget | HTMLFormElement>,
    isDownloadReport?:boolean 
  ) => {
    event.preventDefault();
    setIsLoading(true);
    console.log(event.currentTarget);
    // const enterNameValue = document.getElementById(
    //   "enterNameID"
    // ) as HTMLInputElement | null;
    // const ReasonValue = document.getElementById(
    //   "Reason"
    // ) as HTMLInputElement | null;
    // let enterName = null;
    // let reason = null;
    // if (enterNameValue != null) {
    //   enterName = enterNameValue.value;
    // }
    // if (ReasonValue != null) {
    //   reason = ReasonValue.value;
    // }

    const signalingGateway = state.preflightTest.signalingGatewayReachable
      ? "Reachable"
      : "Unreachable";
    const turnServers = state.preflightTest.turnServersReachable
      ? "Reachable"
      : "Unreachable";
    const maxBitrate = state.bitrateTest.report?.values
      ? Math.max(...state.bitrateTest.report.values)
      : 0;

    const finalTestResults = {
      audioTestResults: {
        inputTest: state.audioInputTestReport,
        outputTest: state.audioOutputTestReport,
      },
      bitrateTestResults: { maxBitrate, ...state.bitrateTest.report },
      browserInformation: userAgentInfo,
      connectivityResults: {
        twilioServices: { ...state.twilioStatus },
        signalingRegion: signalingGateway,
        TURN: turnServers,
      },
      preflightTestReport: {
        report: state.preflightTest.report,
        error: state.preflightTest.error?.message || null,
      },
      videoTestResults: state.videoInputTestReport,
    };

    const formattedDate = moment().utc().format('MMMM Do YYYY hh mm a').replaceAll(' ', '_')
    const testResult = testsPassed ? "All_Tests_Passed" : "Some_Tests_Failed"
    const testReportDescription = testsPassed ? "As far as we can tell, your video should be working" :  "One out of three tests failed - use this list to solve common video issues and restart the test"
    const blitzzServerAPIStatus = state.appIsExpired === true ? "Failed" :  "Pass";
    const blitzzStorageAPIStatus = state.storageAPIStatus === false ? "Failed" :  "Pass";
    const blitzzMediaAPIStatus = state.mediaConnectionStatus === false ? "Failed" :  "Pass";

    const testReport = {
      summary  : testResult,
      Description : testReportDescription,
      Date : formattedDate,
      'Server API Status': blitzzServerAPIStatus,
      'Media API Status': blitzzMediaAPIStatus,
      'Storage API Status': blitzzStorageAPIStatus,
    }

    const reportData = JSON.stringify(finalTestResults, null, 2);

    const updatedData = JSON.parse(reportData)
    const updatedReportData = {testReport, ...updatedData};
    const formattedData = JSON.stringify(updatedReportData, null, 2)

    if(isDownloadReport){
      handleDownloadReport(formattedData, testResult, formattedDate)
    }
    else {

    let url = process.env.REACT_APP_API_END_POINT + NETWORK_TEST_EMAIL;

    const headers = {
      "Content-Type": "application/json",
      CLIENTAPPID: process.env.REACT_APP_CLIENTAPPID,
    };
    let nameValue = enterName;
    let reasonValue = reason;
    const requestData = {
      MAILTYPE: "NETWORKTEST",
      NETWORKTESTRESULT: formattedData,
      NAME: nameValue.trim(),
      REASON: reasonValue.trim(),
      RESULT: testResult
    };
    setIsReportButtonDisable(true);
    axios
      .post(url, requestData, { headers: headers })
      .then((response: any) => {
        if (response.data.success > 0) {
          CustomToast.success("Your report has been sent successfully");
          console.log("response", response);
          setEnterName('')
          setReason('')
          setIsLoading(false);
          setIsReportButtonDisable(false);
          setIsModalOpen(false)
        } else {
          setIsLoading(false);
          setIsReportButtonDisable(false);
          CustomToast.error('Unable to send Report results. Please try again.');
        }
      })
      .catch((error: AxiosError) => {
        setIsLoading(false);
        setIsReportButtonDisable(false);
        CustomToast.error('Unable to send Report results. Please try again.');
      });
    }
  };

  return (
    <>
      <Container>
        <div>
          <Grid item lg={5} className={classes.header}>
            <Typography variant="h1" gutterBottom>
              {testsPassed ? "All tests passed!" : "Some tests failed"}
            </Typography>

            {testsPassed ? (
              <Typography variant="body1" gutterBottom>
                As far as we can tell, your video should be working. If you're
                still experiencing issues, please click “Send Report” 
                to send us a copy of your test result and contact our support team 
                to help you troubleshoot any connectivity issues...
              </Typography>
            ) : (
              <Typography variant="body1" gutterBottom>
                <strong>One out of three </strong>
                tests failed - use this list to solve common video issues and 
                restart the test. If you can’t easily solve the problem(s), 
                click “Send Report” to send us a copy of your test result and
                contact our support team to help you troubleshoot any connectivity issues.
              </Typography>
            )}

            <div>
            <div className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.downloadButton}
                    onClick={() => setIsModalOpen(true)}
                  >
                    <SendIcon />
                    Send Report
                  </Button>
                  <Button
                    variant="outlined"
                    className={classes.restartButton}
                    onClick={() => window.location.reload()}
                  >
                    Restart test
                  </Button>
            </div>
              <div className={classes.downloadReportBtnRow}>
                <Button
                    variant="text"
                    onClick={(e)=> {
                      const downloadReport = true;
                      sendTestResults(e, downloadReport)
                    }}
                    className={classes.downloadReportButton}
                  >
                    Download report
                  </Button>
                  </div>
            </div>

            <Hidden mdDown>
              <img
                src={testsPassed ? TestsPassed : SomeFailed}
                alt={testsPassed ? "Success" : "Some Failed"}
                className={classes.illustration}
              />
            </Hidden>
          </Grid>

          <Grid item lg={5} className={classes.resultsList}>
            <div className={classes.resultContainer}>
              <div className={classes.iconContainer}>
                <CheckMark />
                <Typography variant="h3" className={classes.gutterBottom}>
                  Device &amp; Network Setup
                </Typography>
              </div>
              <Typography variant="body1" className={classes.gutterBottom}>
                Audio and video successfully received from your hardware and
                browser.
              </Typography>

              <div className={classes.buttonContainer}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    dispatch({
                      type: "set-active-pane",
                      newActivePane: ActivePane.CameraTest,
                    })
                  }
                >
                  Review hardware
                </Button>
                <Button
                  variant="outlined"
                  onClick={() =>
                    dispatch({
                      type: "set-active-pane",
                      newActivePane: ActivePane.BrowserTest,
                    })
                  }
                >
                  Review browser
                </Button>
              </div>
            </div>

            <div className={classes.resultContainer}>
              <div className={classes.iconContainer}>
                <CheckMark />
                <Typography variant="h3" className={classes.gutterBottom}>
                  Connectivity
                </Typography>
              </div>
              <Typography variant="body1" className={classes.gutterBottom}>
                All connections are working successfully.
              </Typography>
              <Button
                variant="outlined"
                onClick={() =>
                  dispatch({
                    type: "set-active-pane",
                    newActivePane: ActivePane.Connectivity,
                  })
                }
              >
                Review connectivity
              </Button>
            </div>

            <div className={classes.resultContainer}>
              <div className={classes.iconContainer}>
                {testsPassed ? <CheckMark /> : <SmallError />}
                <Typography variant="h3" className={classes.gutterBottom}>
                  Quality &amp; Performance
                </Typography>
              </div>

              {testsPassed ? (
                <Typography variant="body1" className={classes.gutterBottom}>
                  Awesome! Your expected call quality is{" "}
                  <strong>{qualityScore}</strong> and overall performance looks
                  {qualityScore === "excellent" ? " good" : " ok"}.
                </Typography>
              ) : (
                <Typography variant="body1" className={classes.gutterBottom}>
                  Your overall score is <strong>{qualityScore}</strong> which
                  means that your connection isn't good enough to run video
                  properly. Try out these tips and rerun the test.
                </Typography>
              )}

              <Button
                variant="outlined"
                onClick={() =>
                  dispatch({
                    type: "set-active-pane",
                    newActivePane: ActivePane.Quality,
                  })
                }
              >
                Review performance
              </Button>
            </div>
          </Grid>
        </div>
      </Container>
      <ReportModal
        isModalOpen={isModalOpen}
        isReportButtonDisable={isReportButtonDisable}
        setIsReportButtonDisable={setIsReportButtonDisable}
        setIsModalOpen={setIsModalOpen}
        sendTestResults={(e) => sendTestResults(e)}
        enterName={enterName}
        setEnterName={setEnterName}
        reason={reason}
        setReason={setReason}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </>
  );
};
